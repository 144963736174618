@media (min-width: 600px) {
    .vijay {
      padding: 80px !important;
    }
  }


  /* Maximum width */
  @media (max-width: 600px) {
    .vijay {
     padding: 30px !important;
    }
  }
