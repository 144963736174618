@media (max-width: 768px) {
    .footer__widget--inner {
      display: none; /* Hide the content by default on mobile */
    }
    
    .footer__widget--inner.active {
      display: block; /* Show the content when active */
    }
  }

